var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('my-page-header',{attrs:{"title":_vm.$t('page.header'),"description":_vm.$t('page.description')}}),_vm._v(" "),_c('a-card',{attrs:{"bordered":false}},[_c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.customer.old')}},[_c('span',{staticClass:"ant-form-text"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.droneOwner.name)+"\n\t\t\t\t")])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.customer.new'),"required":true,"validate-status":_vm.$notEmpty(_vm.selectedCustomerMessage) ? 'error' : '',"help":_vm.selectedCustomerMessage,"extra":_vm.$t('drone.field.customer.help')}},[_c('a-button',{attrs:{"icon":"user","type":"dashed"},on:{"click":_vm.handleSelectCustomer}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('register.select_customer.label'))+"\n\t\t\t\t")]),_vm._v(" "),_c('CustomerLiteCard',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedCustomer.mode != 'none'),expression:"selectedCustomer.mode != 'none'"}],staticClass:"register-customer-card",attrs:{"size":"small","customer":_vm.selectedCustomer.customer,"bordered":true}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.changed_date'),"extra":_vm.$t('field.changed_date.help')}},[_c('MyDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['changedDate',
							{
								rules: [
									{ required: true ,message: _vm.$tt('validate.required','field.changed_date') },
								],
							}]),expression:"['changedDate',\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','field.changed_date') },\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t}]"}],attrs:{"format":"DD MMMM YYYY"}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone.field.latest_flight_time_sec'),"extra":_vm.$t('drone.field.latest_flight_time_sec.help')}},[_c('FlightTimeInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'latestFlightTimeSec' ,
						{
							rules: [
							{ required: true ,message: _vm.$tt('validate.required','drone.field.latest_flight_time_sec') },
						] },
					]),expression:"[\n\t\t\t\t\t\t'latestFlightTimeSec' ,\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','drone.field.latest_flight_time_sec') },\n\t\t\t\t\t\t] },\n\t\t\t\t\t]"}]})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.reason')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'reason' ,
						{
							rules: [
							{ required: true ,message: _vm.$tt('validate.required','field.reason') },
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'reason' ,\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true ,message: $tt('validate.required','field.reason') },\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"auto-size":{ minRows: 3,maxRows: 5 },"placeholder":_vm.$t('field.reason.placeholder')}})],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_c('a-button',{style:({ 'margin-right': '4px' }),attrs:{"disabled":_vm.formLoading,"icon":"left","size":"large"},on:{"click":_vm.goBack}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('common.back'))+"\n\t\t\t\t")]),_vm._v(" "),_c('a-button',{staticClass:"btn-submit",attrs:{"loading":_vm.formLoading,"type":"info","html-type":"submit","icon":"file-add","size":"large"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('register.submit.label'))+"\n\t\t\t\t")])],1)],1)],1),_vm._v(" "),_c('CustomerModal',{attrs:{"modal-title":_vm.$t('register.customer.title'),"visible":_vm.visible,"excluded-list":_vm.excludedCustomers,"can-create":true},on:{"select":_vm.handleSelectModal,"close":_vm.handleCloseModal},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }